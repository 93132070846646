import React, { useState } from "react";
import {
  Center,
  Text,
  VStack,
  FormControl,
  Input,
  FormLabel,
  Button,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();
  const toast = useToast();

  const submitHandler = async () => {
    try {
      let newErrors = {};
      if (username === "") newErrors.username = "Enter Your user name";
      if (password === "") newErrors.password = "Enter your password";

      setError(newErrors);
      if (Object.keys(newErrors).length === 0) {
        // console.log(process.env.REACT_APP_BACKEND_URL)
        const response = await fetch(
          `https://surb-backend.vercel.app/api/v1/user/login`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_name: username,
              password: password,
            }),
          }
        );
        const data = await response.json();
        if (data.success === true) {
          localStorage.setItem("token", data.token);
          navigate("/admin/contacts");
        } else {
          toast({
            title: data.msg,
            // description: "Please Enter Valid Username and Password",
            position: "top",
            status: "error",
            duration: 1000,
            isClosable: true,
          });
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Center w={"100vw"} h={"100vh"} bgColor={"white"}>
      <Center
        h={"60%"}
        w={["100%", "80%", "60%", "40%"]}
        borderRadius={"2%"}
        display={"flex"}
        flexDirection={"row"}
        bgColor={"#252B3B"}
        gap={"20%"}
        boxShadow="dark-lg"
      >
        <VStack w={"65%"} spacing={"25px"}>
          <Text
            fontSize={["lg", "xl", "2xl", "2xl", "4xl"]}
            as={"b"}
            mb={4}
            fontFamily={"Times New Roman"}
            color={"white"}
            mt={8}
          >
            {" "}
            Admin Login
          </Text>

          <FormControl id="username" isInvalid={error.username} isRequired>
            <FormLabel color={"white"}>Username</FormLabel>
            <Input
              placeholder="Enter your Username"
              color={"white"}
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                setError({ ...error, username: "" });
              }}
            />
            {error.username ? (
              <FormErrorMessage>{error.username}</FormErrorMessage>
            ) : (
              ""
            )}
          </FormControl>
          <FormControl id="Password" isRequired isInvalid={error.password}>
            <FormLabel color={"white"}>Password</FormLabel>
            <Input
              placeholder="Enter your Password"
              color={"white"}
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError({ ...error, password: "" });
              }}
            />
            {error.password ? (
              <FormErrorMessage color={"red"}>
                {error.password}
              </FormErrorMessage>
            ) : (
              ""
            )}
          </FormControl>
          <Button
            colorScheme="blue"
            width="65%"
            mb={8}
            style={{ marginTop: 15 }}
            fontFamily={"Times New Roman"}
            onClick={submitHandler}
            // isLoading={loading}
          >
            Login
          </Button>
        </VStack>
      </Center>
    </Center>
  );
}
